import {
  ActionIcon,
  Button,
  Group,
  InputWrapper,
  NumberInput,
  Popover,
  Slider,
  Stack,
  TextInput,
} from "@mantine/core";
import { IconRefresh, IconSettings } from "@tabler/icons-react";
import {
  DEFAULT_DIGITS,
  DEFAULT_LENGTH,
  DEFAULT_LOWER_CASE_LETTERS,
  DEFAULT_MIN_DIGITS_COUNT,
  DEFAULT_MIN_LOWER_CASE_LETTERS_COUNT,
  DEFAULT_MIN_SYMBOLS_COUNT,
  DEFAULT_MIN_UPPER_CASE_LETTERS_COUNT,
  DEFAULT_SYMBOLS,
  DEFAULT_UPPER_CASE_LETTERS,
} from "./App";

function Settings({
  length,
  upperCaseLetters,
  lowerCaseLetters,
  digits,
  symbols,
  minUpperCaseLettersCount,
  minLowerCaseLettersCount,
  minDigitsCount,
  minSymbolsCount,
  setLength,
  setUpperCaseLetters,
  setLowerCaseLetters,
  setDigits,
  setSymbols,
  setMinUpperCaseLettersCount,
  setMinLowerCaseLettersCount,
  setMinDigitsCount,
  setMinSymbolsCount,
}: {
  length: number;
  upperCaseLetters: string;
  lowerCaseLetters: string;
  digits: string;
  symbols: string;
  minUpperCaseLettersCount: number;
  minLowerCaseLettersCount: number;
  minDigitsCount: number;
  minSymbolsCount: number;
  setLength: (length: number) => void;
  setUpperCaseLetters: (letters: string) => void;
  setLowerCaseLetters: (letters: string) => void;
  setDigits: (digits: string) => void;
  setSymbols: (symbols: string) => void;
  setMinUpperCaseLettersCount: (count: number) => void;
  setMinLowerCaseLettersCount: (count: number) => void;
  setMinDigitsCount: (count: number) => void;
  setMinSymbolsCount: (count: number) => void;
}) {
  return (
    <Popover width={384} shadow="md">
      <Popover.Target>
        <ActionIcon variant="light" color="gray">
          <IconSettings size="1rem" />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack>
          <InputWrapper label="Length" mb="12">
            <Slider
              value={length}
              onChange={setLength}
              min={Math.max(
                4,
                minUpperCaseLettersCount +
                  minLowerCaseLettersCount +
                  minDigitsCount +
                  minSymbolsCount,
              )}
              max={40}
              marks={[{ label: length, value: length }]}
              label={null}
              size={4}
            />
          </InputWrapper>
          <Group align="end" gap="xs">
            <TextInput
              flex={1}
              label="Upper case letters"
              value={upperCaseLetters}
              onChange={(event) =>
                setUpperCaseLetters(
                  event.currentTarget.value.replace(/[^A-Z]+/g, ""),
                )
              }
            />
            <NumberInput
              description="Min"
              w={60}
              value={minUpperCaseLettersCount}
              onChange={(event) => setMinUpperCaseLettersCount(Number(event))}
              min={0}
              max={Math.min(Math.floor(length / 4), 9)}
              allowDecimal={false}
            />
          </Group>
          <Group align="end" gap="xs">
            <TextInput
              flex={1}
              label="Lower case letters"
              value={lowerCaseLetters}
              onChange={(event) =>
                setLowerCaseLetters(
                  event.currentTarget.value.replace(/[^a-z]+/g, ""),
                )
              }
            />
            <NumberInput
              description="Min"
              w={60}
              value={minLowerCaseLettersCount}
              onChange={(event) => setMinLowerCaseLettersCount(Number(event))}
              min={0}
              max={Math.min(Math.floor(length / 4), 9)}
              allowDecimal={false}
            />
          </Group>
          <Group align="end" gap="xs">
            <TextInput
              flex={1}
              label="Digits"
              value={digits}
              onChange={(event) =>
                setDigits(event.currentTarget.value.replace(/[^0-9]+/g, ""))
              }
            />
            <NumberInput
              description="Min"
              w={60}
              value={minDigitsCount}
              onChange={(event) => setMinDigitsCount(Number(event))}
              min={0}
              max={Math.min(Math.floor(length / 4), 9)}
              allowDecimal={false}
            />
          </Group>
          <Group align="end" gap="xs">
            <TextInput
              flex={1}
              label="Symbols"
              value={symbols}
              onChange={(event) =>
                setSymbols(event.currentTarget.value.replace(/[A-Z0-9]+/gi, ""))
              }
            />
            <NumberInput
              description="Min"
              w={60}
              value={minSymbolsCount}
              onChange={(event) => setMinSymbolsCount(Number(event))}
              min={0}
              max={Math.min(Math.floor(length / 4), 9)}
              allowDecimal={false}
            />
          </Group>
          <Button
            variant="light"
            leftSection={<IconRefresh size="1rem" />}
            onClick={() => {
              setLength(DEFAULT_LENGTH);
              setUpperCaseLetters(DEFAULT_UPPER_CASE_LETTERS);
              setLowerCaseLetters(DEFAULT_LOWER_CASE_LETTERS);
              setDigits(DEFAULT_DIGITS);
              setSymbols(DEFAULT_SYMBOLS);
              setMinUpperCaseLettersCount(DEFAULT_MIN_UPPER_CASE_LETTERS_COUNT);
              setMinLowerCaseLettersCount(DEFAULT_MIN_LOWER_CASE_LETTERS_COUNT);
              setMinDigitsCount(DEFAULT_MIN_DIGITS_COUNT);
              setMinSymbolsCount(DEFAULT_MIN_SYMBOLS_COUNT);
            }}
          >
            Reset
          </Button>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
}

export default Settings;
