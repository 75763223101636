export function generatePassword({
  seed,
  length = 12,
  groups,
}: {
  seed?: string;
  length?: number;
  groups: {
    value: string;
    min: number;
  }[];
}) {
  // Ensure the total minimum requirement doesn't exceed the password length
  const totalMin = groups.reduce(
    (sum, group) => sum + (group.value ? group.min : 0),
    0,
  );

  if (totalMin > length) {
    throw new Error("Minimum constraints exceed the password length.");
  }

  // Deterministic PRNG using seed
  let seedIndex = 0;
  const random = (): number => {
    const charCodeSum = (seed || String(Math.random()))
      .split("")
      .reduce((sum, char) => sum + char.charCodeAt(0) * (seedIndex++ + 1), 0);

    return Math.abs(Math.sin(charCodeSum)) % 1;
  };

  const getRandomIndex = (str: string): number =>
    Math.floor(random() * str.length);

  const pickRandomChars = (pool: string, count: number): string => {
    let result = "";
    for (let i = 0; i < count; i++) {
      result += pool[getRandomIndex(pool)] ?? "";
    }
    return result;
  };

  // Generate required sections
  const groupSection = groups.reduce(
    (password, group) =>
      password + pickRandomChars(group.value, group.value ? group.min : 0),
    "",
  );

  // Fill remaining characters from all pools
  const allChars = groups.reduce((chars, group) => chars + group.value, "");
  const remainingLength = length - totalMin;
  const remainingSection = pickRandomChars(allChars, remainingLength);

  // Combine and shuffle
  const passwordArray = (groupSection + remainingSection).split("");

  for (let i = passwordArray.length - 1; i > 0; i--) {
    const j = Math.floor(random() * (i + 1));
    [passwordArray[i], passwordArray[j]] = [passwordArray[j], passwordArray[i]];
  }

  return passwordArray.join("");
}
