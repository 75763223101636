import { useRef, useState } from "react";
import { generatePassword } from "./password-generator";
import {
  Alert,
  Box,
  Button,
  Card,
  CopyButton,
  Divider,
  Group,
  PasswordInput,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { IconCheck, IconCopy } from "@tabler/icons-react";
import Settings from "./Settings";

export const DEFAULT_LENGTH = 20;
export const DEFAULT_UPPER_CASE_LETTERS = "ABCDEFGHJKMNPQRSTUVWXYZ";
export const DEFAULT_LOWER_CASE_LETTERS = "abcdefghjkmnpqrstuvwxyz";
export const DEFAULT_DIGITS = "23456789";
export const DEFAULT_SYMBOLS = "@&$!#?";
export const DEFAULT_MIN_UPPER_CASE_LETTERS_COUNT = 3;
export const DEFAULT_MIN_LOWER_CASE_LETTERS_COUNT = 3;
export const DEFAULT_MIN_DIGITS_COUNT = 3;
export const DEFAULT_MIN_SYMBOLS_COUNT = 3;

function App() {
  const seedInputRef = useRef<HTMLInputElement>(null);

  const [password, setPassword] = useState("");
  const [seed, setSeed] = useState("");

  const [length, setLength] = useState(DEFAULT_LENGTH);
  const [upperCaseLetters, setUpperCaseLetters] = useState(
    DEFAULT_UPPER_CASE_LETTERS,
  );
  const [lowerCaseLetters, setLowerCaseLetters] = useState(
    DEFAULT_LOWER_CASE_LETTERS,
  );
  const [digits, setDigits] = useState(DEFAULT_DIGITS);
  const [symbols, setSymbols] = useState(DEFAULT_SYMBOLS);
  const [minUpperCaseLettersCount, setMinUpperCaseLettersCount] = useState(
    DEFAULT_MIN_UPPER_CASE_LETTERS_COUNT,
  );
  const [minLowerCaseLettersCount, setMinLowerCaseLettersCount] = useState(
    DEFAULT_MIN_LOWER_CASE_LETTERS_COUNT,
  );
  const [minDigitsCount, setMinDigitsCount] = useState(
    DEFAULT_MIN_DIGITS_COUNT,
  );
  const [minSymbolsCount, setMinSymbolsCount] = useState(
    DEFAULT_MIN_SYMBOLS_COUNT,
  );

  const hashedPassword =
    password && seed
      ? generatePassword({
          seed: password + seed,
          length,
          groups: [
            {
              value: upperCaseLetters,
              min: minUpperCaseLettersCount,
            },
            {
              value: lowerCaseLetters,
              min: minLowerCaseLettersCount,
            },
            {
              value: digits,
              min: minDigitsCount,
            },
            {
              value: symbols,
              min: minSymbolsCount,
            },
          ],
        })
      : "";

  return (
    <Box m="1rem">
      <Card maw={384} mx="auto" withBorder>
        <Stack>
          <Group justify="space-between">
            <Text fw={600}>Password generator</Text>
            <Settings
              length={length}
              upperCaseLetters={upperCaseLetters}
              lowerCaseLetters={lowerCaseLetters}
              digits={digits}
              symbols={symbols}
              minUpperCaseLettersCount={minUpperCaseLettersCount}
              minLowerCaseLettersCount={minLowerCaseLettersCount}
              minDigitsCount={minDigitsCount}
              minSymbolsCount={minSymbolsCount}
              setLength={setLength}
              setUpperCaseLetters={setUpperCaseLetters}
              setLowerCaseLetters={setLowerCaseLetters}
              setDigits={setDigits}
              setSymbols={setSymbols}
              setMinUpperCaseLettersCount={setMinUpperCaseLettersCount}
              setMinLowerCaseLettersCount={setMinLowerCaseLettersCount}
              setMinDigitsCount={setMinDigitsCount}
              setMinSymbolsCount={setMinSymbolsCount}
            />
          </Group>
          <Divider />
          <PasswordInput
            autoFocus
            label="Password"
            placeholder="Enter password"
            value={password}
            onChange={(event) => setPassword(event.currentTarget.value)}
            rightSection=""
            onKeyDown={(event) => {
              if (event.key === "Enter" && seedInputRef.current) {
                seedInputRef.current.focus();
              }
            }}
          />
          <TextInput
            ref={seedInputRef}
            label="Seed"
            placeholder="Enter seed"
            value={seed}
            onChange={(event) =>
              setSeed(
                event.currentTarget.value
                  .toLowerCase()
                  .replace(/[^a-z0-9_.-]+/g, ""),
              )
            }
          />

          {hashedPassword && (
            <Alert title="Secure password">
              <Group gap="xs">
                <TextInput value={hashedPassword} readOnly flex={1} />

                <CopyButton value={hashedPassword}>
                  {({ copied, copy }) => (
                    <Button
                      leftSection={
                        copied ? (
                          <IconCheck size="1rem" />
                        ) : (
                          <IconCopy size="1rem" />
                        )
                      }
                      color={copied ? "teal" : "blue"}
                      onClick={copy}
                    >
                      {copied ? "Copied" : "Copy"}
                    </Button>
                  )}
                </CopyButton>
              </Group>
            </Alert>
          )}
        </Stack>
      </Card>
    </Box>
  );
}

export default App;
